.kit-order .bp3-spinner-animation svg {
    height: 38px;
    width: 38px;
}
.patient-select {
    margin-bottom: 5px;
}
.patient-select .invalid-feedback {
    margin-top: 0;
    color: green;
}
.d-block {
    display: block;
}