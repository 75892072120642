.error-text {
    height: 20px;
}
.multi-select {
    width: 200px;
}
.recipients-form {
    padding-left: 100px;
    padding-right: 200px;
}
.height-options-2 {
    height: 160px;
}
.height-options-3 {
    height: 190px;
}
.height-options-4 {
    height: 230px;
}
.height-options-5 {
    height: 260px;
}