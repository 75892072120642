.new-case-modal.bp3-dialog {
    background-color: #fff;
}
.new-case-modal .bp3-dialog-header {
    background: #f0f8fd;
    padding: 1rem;
}

.new-case-modal .bp3-heading {
    font-weight: 400;
}
