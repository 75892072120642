.th-width-90 {
    width: 90px
}
.th-width-135 {
    width: 135px
}
.generateOrBlock {
    display: flex;
    align-items: center
}
.blockReport {
    margin-left: 10%;
    margin-bottom: 0
}